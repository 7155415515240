const baseTheme = {
  fonts: {
    mono: '"SF Mono", "Roboto Mono", Menlo, monospace',
  },
};

const lightTheme = {
  ...baseTheme,
  colors: {
    background: '#1b1f22',
    heading: '#fafafa',
    text: '#fafafa',
    preFormattedText: '#1b1f22',
    //preFormattedText: 'rgb(245, 247, 249)',
    link: '#00ccff',
  },
};

const darkTheme = {
  ...baseTheme,
  colors: {
    background: '#001933',
    heading: '#fff',
    text: '#fff',
    preFormattedText: '#000',
    link: '#00ccff',
  },
};

export { lightTheme, darkTheme };
