import * as React from 'react';
import Link from './../link';
import path from 'path';

const AnchorTag = ({ children: link, ...props }) => {
  if (link && (props.href.startsWith('http') || props.href.startsWith('https'))) {
    return (
      <a href={props.href} target="_blank" rel="noopener noreferrer">
        {link}
      </a>
    );
  } else if (link && path.extname(props.href)) {
    return (
      <a href={props.href} target="_blank" rel="noopener noreferrer">
        {link}
      </a>
    )
  } else if (link) {
    <Link to={props.href}>
      {link}
    </Link>
  } else {
    return null;
  }
};

export default AnchorTag;
