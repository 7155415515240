//const isProduction = process.env.NODE_ENV === "production";
const config = {
  gatsby: {
    pathPrefix: '/',
    siteUrl: 'https://tech.aventureworld.com',
    gaTrackingId: 'G-KS1WBJLWB8',
    trailingSlash: false,
  },
  header: {
    logo: 'https://aventureworld.com/icons/icon-256x256.png',
    logoLink: '/',
    title:
      "<a href='/'>TECH A VENTURE WORLD</a>",
    githubUrl: '',
    helpUrl: '',
    tweetText: '',
    social: ``,
    //social: `<li style="display: flex;">
    //    <a href="https://twitter.com/creator_haduki" target="_blank" rel="noopener">
    //      <div class="twitterBtn">
    //        <img src='/twitter.svg' alt={'Twitter'}/>
    //      </div>
    //    </a>
    //    <a href="https://www.instagram.com/creator_haduki/" target="_blank" rel="noopener">
    //      <div class="instagramBtn">
    //        <img src='/instagram.png' alt={'Instagram'}/>
    //      </div>
    //    </a>
    //    <a href="https://note.com/creator_haduki" target="_blank" rel="noopener">
    //      <div class="twitterBtn">
    //        <img src='/note.svg' alt={'note'}/>
    //      </div>
    //    </a>
    //  </li>
    //  `,
//      +
//      `<li>
//		    <a href="https://discordapp.com/invite/hasura" target="_blank" rel="noopener">
//		      <div class="discordBtn">
//		        <img src='https://graphql-engine-cdn.hasura.io/learn-hasura/assets/homepage/discord-brands-block.svg' alt={'Discord'}/>
//		      </div>
//		    </a>
//		  </li>`,
    links: [
      { text: '', link: '' }
    ],
    search: {
      enabled: false,
      indexName: '',
      algoliaAppId: process.env.GATSBY_ALGOLIA_APP_ID,
      algoliaSearchKey: process.env.GATSBY_ALGOLIA_SEARCH_KEY,
      algoliaAdminKey: process.env.ALGOLIA_ADMIN_KEY,
    },
  },
  sidebar: {
    forcedNavOrder: [
      //'/bookmark',
      //'/linux',
    ],
    collapsedNav: [
      //'/bookmark',
      //'/linux',
    ],
    links: [
      { text: 'aventureworld.com', link: 'https://aventureworld.com/' },
      //{ text: 'Twitter', link: 'https://twitter.com/creator_haduki' },
      //{ text: 'Instagram', link: 'https://www.instagram.com/creator_haduki/' },
      //{ text: 'note', link: 'https://note.com/creator_haduki' },
    ],
    frontline: false,
    ignoreIndex: true,
    //title:
    //  `
    //  <a href='https://aventureworld.com/'>aventureworld.com</a>
    //  <div class='darkCircle'></div>
    //  <a href='https://aventureworld.com/'>blog</a>
    //  `,
  },
  siteMetadata: {
    title: 'TECH A VENTURE WORLD | 技術情報まとめサイト',
    description: '技術的情報を発信します。',
    ogImage: null,
    docsLocation: '',//'https://github.com/hasura/gatsby-gitbook-boilerplate/tree/master/content',
    favicon: 'https://aventureworld.com/icons/icon-256x256.png',
  },
  pwa: {
    enabled: false, // disabling this will also remove the existing service worker.
    manifest: {
      name: 'TECH A VENTURE WORLD | 技術情報まとめサイト',
      short_name: 'TECH A VENTURE WORLD | 技術情報まとめサイト',
      start_url: '/',
      background_color: '#6b37bf',
      theme_color: '#6b37bf',
      display: 'standalone',
      crossOrigin: 'use-credentials',
      icons: [
        {
          src: 'src/pwa-512.png',
          sizes: `512x512`,
          type: `image/png`,
        },
      ],
    },
  },
};

module.exports = config;
