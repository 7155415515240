import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import isAbsoluteUrl from 'is-absolute-url';
import config from '../../config';

const Link = ({ to, ...props }) => {
  return isAbsoluteUrl(to) ? (
    <a href={to} {...props}>
      {props.children}
    </a>
  ) : (
    props.isTo ?
    <GatsbyLink to={to + (config.gatsby.trailingSlash ? "" : "")} {...props} />
    :
    <GatsbyLink href={to + (config.gatsby.trailingSlash ? "" : "")} {...props} />
  );
}

export default Link;
